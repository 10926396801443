<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="currentPage === 'default'" class="pa-0">
      <v-sheet
        ref="sheet"
        color="primary"
        class="pa-2"
        :class="{
          'v-sheet__desktop': $voicer.isDesktopOrTablet,
          'ma-3 mb-4': $voicer.isDesktopOrTablet,
        }"
      >
        <div class="pl-2 pr-2 pt-1 white--text">
          Bonjour et bienvenue sur <em>emeis</em> RADIO, la&nbsp;&nbsp;radio qui
          parle de vous&#x205F;!
          <br />
          Vous souhaitez suggérer un sujet de reportage&#x205F;? <br />
          Nous sommes à votre écoute&#x205F;!
        </div>
        <div>
          <v-btn
            color="white orange--text"
            @click.stop="showFirstFeedbackModal = true"
          >
            <v-icon color="primary" class="mr-2"> mdi-pencil </v-icon>

            J'écris à la radio
          </v-btn>
        </div>
        <w-feedback-popup id="firstFeedback" v-model="showFirstFeedbackModal" />
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import WFeedbackPopup from '../components/WFeedbackPopup'

export default {
  components: {
    WFeedbackPopup,
  },
  data() {
    return {
      showFirstFeedbackModal: false,
      showSecondFeedbackModal: false,
      showFeedbackModal: false,
    }
  },
  computed: {
    ...mapGetters({
      currentPage: 'root/currentPage',
    }),
  },

  methods: {
    handleOpen(e) {
      this.$store.dispatch('player/changePlayerContent', {
        content: this.$spoke.document('63fcb12f06470e7729aebfa1').lean(),
        noRedirect: false,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.w-block__pre-heading {
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 500;

  .v-sheet {
    border-radius: 8px;
    text-align: center;
  }

  .v-btn {
    border-radius: 4px;
  }
}

@media screen and (max-width: 600px) {
  .message_welcome_sir {
    flex-direction: column;
    align-items: center;
    div {
      margin-top: 15px;
    }
  }
}
</style>
