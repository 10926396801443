<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="currentPage === 'default'" class="pa-0">
      <v-sheet
        ref="sheet"
        color="accent"
        class="pa-2"
        :class="{
          'v-sheet__desktop': $voicer.isDesktopOrTablet,
          'ma-3 mb-4': $voicer.isDesktopOrTablet,
        }"
      >
        <div class="pl-2 pr-2 pt-1 white--text">
          Vous souhaitez mettre un collègue à l’honneur&#x205F;?<br />
          Faire passer un message de sympathie ou de remerciement&#x205F;?
          <br />
          Nous sommes à votre écoute&#x205F;!
        </div>
        <div class="carre">
          <div>
            <v-btn
              color="white accent--text"
              @click.stop="showSecondFeedbackModal = true"
            >
              <v-icon color="accent" class="mr-2"> mdi-microphone </v-icon>

              J'enregistre ma dédicace
            </v-btn>
            <w-feedback-popup
              id="secondFeedback"
              v-model="showSecondFeedbackModal"
            />
          </div>

          <div>
            <v-btn
              color="white accent--text"
              @click.stop="showThirdFeedbackModal = true"
            >
              <v-icon color="accent" class="mr-2"> mdi-pencil </v-icon>

              J'écris ma dédicace
            </v-btn>
          </div>
          <w-feedback-popup
            id="thirdFeedback"
            v-model="showThirdFeedbackModal"
          />
        </div>
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import WFeedbackPopup from '../components/WFeedbackPopup'

export default {
  components: {
    WFeedbackPopup,
  },
  data() {
    return {
      showFeedbackModal: false,
      showSecondFeedbackModal: false,
      showThirdFeedbackModal: false,
    }
  },
  computed: {
    ...mapGetters({
      currentPage: 'root/currentPage',
    }),
  },

  methods: {
    handleOpen(e) {
      this.$store.dispatch('player/changePlayerContent', {
        content: this.$spoke.document('63fcb12f06470e7729aebfa1').lean(),
        noRedirect: false,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.carre {
  display: flex;
  justify-content: center;
}
.w-block__pre-heading {
  justify-content: center;
  text-align: center;
  font-weight: 500;

  .v-sheet {
    border-radius: 8px;
  }

  .v-btn {
    border-radius: 4px;
  }
}

@media screen and (max-width: 600px) {
  .carre {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
</style>
