<template>
  <div class="w-block__pre-login">
    <!-- pre-login block -->
  </div>
</template>
<script>
export default {
  watch: {
    open(status) {
      if (status === false) {
        this.$store.dispatch('player/openPersistantPlayer')
      }
    },
  },
  methods: {
    handleClick() {
      this.open = false
      this.$emit('open-form')
    },
  },
}
</script>
<style lang="scss">
.body2 {
  color: #111212;
  text-align: center;
  justify-content: center;
}
/* desktops */
@media (min-width: 992px) {
  .body2 {
    font-size: 16px;
  }
}
/* mobiles */
@media (max-width: 768px) {
  .body2 {
    font-size: 12px;
  }
}
</style>
